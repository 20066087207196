require('./bootstrap');
// Register $ global var for jQuery
global.$ = global.jQuery = require('jquery');
import $ from 'jquery';
window.$ = window.jQuery = $;
window.JSZip =require( 'jszip' );
window.pdfMake=require( 'pdfmake' );
window.pdfMake = require( 'pdfmake' );
window.pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
require('datatables.net');
require( 'datatables.net-bs4' );
require( 'datatables.net-buttons-bs4' );

require( 'datatables.net-buttons/js/buttons.colVis.js' );
require( 'datatables.net-buttons/js/buttons.html5.js' );
require( 'datatables.net-buttons/js/buttons.print.js' );
require( 'datatables.net-responsive-bs4' );
// Import jQuery Plugins
//import 'jquery-ui/ui/widgets/datepicker.js';

